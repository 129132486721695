import { Text } from "@clipboard-health/ui-react";
import { Box, Button, List, ListItem, Stack } from "@mui/material";
import { SUPPORT_LINKS } from "@src/appV2/Streaks/constants";
import { getFormattedStreaksStartDateInLocalTimezone } from "@src/appV2/Streaks/utils";

export function StreakInstructions() {
  return (
    <>
      <Box>
        <Text variant="h1">How Streaks Work</Text>
        <List>
          <ListItem disableGutters>
            <Stack direction="row" spacing={2}>
              <Text>1</Text>
              <Text variant="body1">
                If you work multiple shifts at a facility in any given week, you may be eligible for{" "}
                <b>extra pay</b> in the following week, for that facility.
              </Text>
            </Stack>
          </ListItem>
          <ListItem disableGutters>
            <Stack direction="row" spacing={2}>
              <Text>2</Text>
              <Text>
                Streak pay is included in the displayed shift pay and will be automatically included
                in your payment when the shift is completed.
              </Text>
            </Stack>
          </ListItem>
          <ListItem disableGutters>
            <Stack direction="row" spacing={2}>
              <Text>3</Text>
              <Text>
                Not all facilities are participating in the Streaks program, and facilities may have
                unique multipliers and hourly requirements. Check the facility page for details.
              </Text>
            </Stack>
          </ListItem>
          <ListItem disableGutters>
            <Stack direction="row" spacing={2}>
              <Text>4</Text>
              <Text>
                Streaks refresh on <b>{getFormattedStreaksStartDateInLocalTimezone()}</b>.
              </Text>
            </Stack>
          </ListItem>
        </List>
      </Box>
      <Box sx={{ alignSelf: "center" }}>
        <Button variant="contained" target="_blank" href={SUPPORT_LINKS.LEARN_MORE_ABOUT_STREAKS}>
          Learn More
        </Button>
      </Box>
    </>
  );
}
