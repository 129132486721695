import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { type PayModifier } from "@src/appV2/Streaks/components/PayModifierBar";

import { PayModifierTag } from "./PayModifierTag";

interface PayModifierDialogProps {
  modalState: UseModalState;
  payModifiers: PayModifier[];
}

export function PayModifierDialog(props: PayModifierDialogProps) {
  const { modalState, payModifiers } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <Text bold variant="h3" align="center">
          Pay Modifiers
        </Text>
      }
      actions={
        <Button
          fullWidth
          variant="contained"
          onClick={(event) => {
            event.preventDefault();
            modalState.closeModal();
          }}
        >
          OK
        </Button>
      }
    >
      {payModifiers.length > 0 ? (
        <Stack spacing={1}>
          <Text variant="caption" sx={{ fontStyle: "italic" }}>
            Modifiers are included in the total pay shown.
          </Text>
          {payModifiers.map((payModifier) => (
            <Stack key={payModifier.name}>
              <PayModifierTag payModifier={payModifier} />
              <Text variant="subtitle2">{payModifier.description}</Text>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Text variant="subtitle2" align="center">
          There are no pay modifiers on this shift.
        </Text>
      )}
    </CommonDialog>
  );
}
