import { Text } from "@clipboard-health/ui-react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Card, CardActionArea, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { useFacilityProfile } from "@src/appV2/Facilities/api/useFacilityProfile";
import { formatPayBoostPercentage } from "@src/appV2/Streaks/utils";

export interface StreakBonusAtFacilityCardProps {
  facilityId: string;
  payBoostPercentage: number;
  onClick: () => void;
}

export function StreakBonusAtFacilityCard(props: StreakBonusAtFacilityCardProps) {
  const { facilityId, payBoostPercentage, onClick } = props;
  const bonusText = `Extra ${formatPayBoostPercentage(payBoostPercentage)} of pay`;

  const { data, isLoading } = useFacilityProfile({ workplaceId: facilityId, projection: "type" });

  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Grid container columnSpacing={1}>
            <Grid item xs={11}>
              <Stack spacing={1} display="flex" justifyContent="space-between">
                <Text variant="body1">
                  {isLoading ? <Skeleton animation="wave" /> : data?.name}
                </Text>
                <Text bold sx={{ color: (theme) => theme.palette.success.main }} variant="body2">
                  {bonusText}
                </Text>
              </Stack>
            </Grid>
            <Grid item xs={1} alignContent="center" justifyContent="flex-end">
              <ChevronRightIcon />
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
