import { Text } from "@clipboard-health/ui-react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  type LinearProgressProps,
  Stack,
} from "@mui/material";
import { AgentStreakProgressState } from "@src/appV2/Streaks/types";
import { clamp, round } from "lodash";

export interface StreakProgressBarCardProps {
  facilityName: string;
  minHoursForActiveStreak: number;
  verifiedHoursInStreakPeriod: number;
  unverifiedHoursInStreakPeriod: number;
  status: AgentStreakProgressState;
  onClick?: () => void;
}

function getStreakStatus(
  participating: boolean,
  remainingHours: number
): {
  statusMessage: string;
  color: LinearProgressProps["color"];
} {
  if (!participating) {
    return {
      statusMessage: "Not participating in Streaks",
      color: undefined,
    };
  }

  if (remainingHours <= 0) {
    return {
      statusMessage: "Bonus unlocked for next week!",
      color: "success",
    };
  }

  return {
    statusMessage: `${round(remainingHours, 2)} verified hours left!`,
    color: "primary",
  };
}

export function StreakProgressBarCard(props: StreakProgressBarCardProps) {
  const {
    facilityName,
    minHoursForActiveStreak,
    unverifiedHoursInStreakPeriod,
    verifiedHoursInStreakPeriod,
    onClick,
    status,
  } = props;

  const participating =
    status === AgentStreakProgressState.COMPLETE || status === AgentStreakProgressState.IN_PROGRESS;
  const remainingHours = clamp(
    minHoursForActiveStreak - verifiedHoursInStreakPeriod,
    0,
    minHoursForActiveStreak
  );
  const { statusMessage, color } = getStreakStatus(participating, remainingHours);

  const progressPercentage = clamp(
    (verifiedHoursInStreakPeriod / minHoursForActiveStreak) * 100,
    0,
    100
  );
  const bufferPercentage = clamp(
    ((unverifiedHoursInStreakPeriod + verifiedHoursInStreakPeriod) / minHoursForActiveStreak) * 100,
    0,
    100
  );

  return (
    <Card variant="outlined">
      <CardActionArea onClick={onClick}>
        <CardContent
          sx={{
            padding: 1,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack spacing={1} flexGrow={1}>
              <Text variant="body1">{facilityName}</Text>
              {participating && (
                <LinearProgress
                  sx={{
                    height: 6,
                    [`& .${linearProgressClasses.dashed}`]: {
                      visibility: "hidden", // hides the loading animation that comes with the default buffer bar
                    },
                  }}
                  variant={
                    status === AgentStreakProgressState.IN_PROGRESS ? "buffer" : "determinate"
                  }
                  value={progressPercentage}
                  valueBuffer={bufferPercentage}
                  color={color}
                />
              )}
              <Text
                bold
                variant="caption"
                sx={{
                  color: (theme) => {
                    switch (color) {
                      case "success": {
                        return theme.palette.success.main;
                      }

                      case "primary": {
                        return theme.palette.primary.main;
                      }

                      default: {
                        return theme.palette.text.disabled;
                      }
                    }
                  },
                }}
              >
                {statusMessage}
              </Text>
            </Stack>
            <ChevronRightIcon fontSize="large" sx={{ color: "gray" }} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
