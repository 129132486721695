import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { DEFAULT_QUERY_OPTIONS } from "@src/appV2/Streaks/constants";
import {
  type AgentStreaksAPIResponse,
  type GetAgentStreaksParams,
  getAgentStreaksParamsSchema,
  getAgentStreaksRequestSchema,
  getAgentStreaksResponseSchema,
} from "@src/appV2/Streaks/types";
import type { UseQueryResult } from "@tanstack/react-query";

export function useGetAgentActiveStreaks(
  params: GetAgentStreaksParams,
  options: UseGetQueryOptions<AgentStreaksAPIResponse> = {}
): UseQueryResult<AgentStreaksAPIResponse> {
  const { agentId, filter } = getAgentStreaksParamsSchema.parse(params);

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/agent/${agentId}/streaks`,
    queryParams: {
      filter,
    },
    requestSchema: getAgentStreaksRequestSchema,
    responseSchema: getAgentStreaksResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_STREAKS_FAILURE,
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
}
