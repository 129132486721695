import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { Alert } from "@mui/material";
import { type ReactNode } from "react";

export interface StreakAlertProps {
  children: ReactNode;
}

export function StreakAlert(props: StreakAlertProps) {
  const { children } = props;

  return (
    <Alert
      severity="success"
      icon={<LocalFireDepartmentOutlinedIcon fontSize="small" />}
      sx={{
        padding: 1,
        display: "flex",
        alignItems: "center",
        ".MuiAlert-message": {
          padding: 0,
        },
        ".MuiAlert-icon": {
          padding: 0,
        },
      }}
      aria-label="streak alert"
    >
      {children}
    </Alert>
  );
}
