import { useModalState } from "@clipboard-health/ui-react";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Stack } from "@mui/material";
import { PayModifierDialog } from "@src/appV2/Streaks/components/PayModifierDialog";
import { PayModifierTag } from "@src/appV2/Streaks/components/PayModifierTag";
import { type ReactNode } from "react";

export interface PayModifier {
  name: string;
  icon: ReactNode;
  color: string;
  description: ReactNode;
}
export interface PayModifierBarProps {
  payModifiers: PayModifier[];
}

export function PayModifierBar(props: PayModifierBarProps) {
  const { payModifiers } = props;
  const modalState = useModalState();

  return (
    <Box aria-label="Pay modifier bar">
      <Box sx={{ backgroundColor: "#F8F8F8", borderRadius: "5px" }} padding={1}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Box flexGrow={1}>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {payModifiers.map((payModifier) => (
                <PayModifierTag
                  key={payModifier.name}
                  payModifier={payModifier}
                  sx={{ marginRight: 1 }}
                />
              ))}
            </Box>
          </Box>
          <IconButton
            aria-label="more info"
            sx={{ color: "#031426", padding: 0 }}
            onClick={(event) => {
              event.preventDefault();
              modalState.openModal();
            }}
          >
            {modalState.modalIsClosed ? <InfoOutlinedIcon /> : <InfoIcon />}
          </IconButton>
        </Stack>
      </Box>
      <PayModifierDialog modalState={modalState} payModifiers={payModifiers} />
    </Box>
  );
}
