import { isDefined } from "@clipboard-health/util-ts";
import { useGetRateNegotiations } from "@src/appV2/Negotiations/api/useGetRateNegotiations";
import { RateNegotiationStatus } from "@src/appV2/Shifts/Shift/types";
import { useGetStreakAlertInfo } from "@src/appV2/Streaks/hooks/useGetStreakAlertInfo";

import { StreakAlert } from "./StreakAlert";
import { StreakPayModifierBar } from "./StreakPayModifierBar";

interface StreakAlertWrapperProps {
  shiftStartTime: Date;
  shiftEndTime: Date;
  workerId: string;
  workplaceId: string;
  onlyCheckActiveStreak: boolean;
  isOpenShift?: boolean;
  isForFacilityOpenShift?: boolean;
  shiftId?: string;
  isUrgentShift?: boolean;
}

export function StreakAlertWrapper(props: StreakAlertWrapperProps) {
  const {
    shiftStartTime,
    shiftEndTime,
    workerId,
    workplaceId,
    onlyCheckActiveStreak,
    isOpenShift,
    isForFacilityOpenShift,
    shiftId,
    isUrgentShift = false,
  } = props;
  const { message: streakAlertMessage, shiftIsQualifiesForStreakBonus } = useGetStreakAlertInfo({
    shiftStartTime,
    shiftEndTime,
    workerId,
    workplaceId,
    onlyCheckActiveStreak,
    isOpenShift,
    isForFacilityOpenShift,
  });
  const shouldShowStreakPayModifier = shiftIsQualifiesForStreakBonus;

  const shiftIds = isDefined(shiftId) ? [shiftId] : [];
  const { data: rateNegotiations } = useGetRateNegotiations(
    {
      shiftIds,
    },
    { enabled: shiftIds.length > 0 && shouldShowStreakPayModifier && !isUrgentShift }
  );
  const shiftRateNegotiationStatus = rateNegotiations?.[0]?.status;

  if (!isDefined(streakAlertMessage)) {
    return null;
  }

  if (!shouldShowStreakPayModifier) {
    return <StreakAlert>{streakAlertMessage}</StreakAlert>;
  }

  if (
    isDefined(shiftRateNegotiationStatus) &&
    [RateNegotiationStatus.OPEN, RateNegotiationStatus.ACCEPTED].includes(
      shiftRateNegotiationStatus
    )
  ) {
    return null;
  }

  return <StreakPayModifierBar streakPayDescription={streakAlertMessage} />;
}
