import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { DEFAULT_QUERY_OPTIONS } from "@src/appV2/Streaks/constants";
import {
  type AgentStreaksProgressApiResponse,
  getAgentStreaksParamsSchema,
  type GetAgentStreaksProgressParams,
  getAgentStreaksProgressResponseSchema,
  getAgentStreaksRequestSchema,
} from "@src/appV2/Streaks/types";
import { type UseQueryResult } from "@tanstack/react-query";

export function useGetAgentStreaksProgress(
  params: GetAgentStreaksProgressParams,
  options?: UseGetQueryOptions<AgentStreaksProgressApiResponse>
): UseQueryResult<AgentStreaksProgressApiResponse> {
  const { agentId, filter } = getAgentStreaksParamsSchema.parse(params);
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/agent/${agentId}/streaks/progress`,
    queryParams: {
      filter,
    },
    requestSchema: getAgentStreaksRequestSchema,
    responseSchema: getAgentStreaksProgressResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_STREAKS_PROGRESS_FAILURE,
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
}
