import { isDefined } from "@clipboard-health/util-ts";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { DEFAULT_QUERY_OPTIONS } from "@src/appV2/Streaks/constants";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const getStreakSettingsParamsSchema = z
  .object({
    facilityId: z.string().optional(),
    msa: z.string().optional(),
  })
  .transform((params) => {
    // the API really should only accept one of these at a time since a settings object should only have EITHER an MSA or a Facility ID
    // That's because settings with the MSA are MSA wide settings and settings with facility IDs are facility specific settings
    if (isDefined(params.facilityId) && isDefined(params.msa)) {
      return {
        facilityId: params.facilityId, // always prefer facility ID if it's present
      };
    }

    return params;
  });

export type GetStreakSettingsParams = z.infer<typeof getStreakSettingsParamsSchema>;

const streakSettingsSchema = z.object({
  id: z.string(),
  type: z.literal("STREAKS"),
  attributes: z.object({
    msa: z.string().nullish(),
    facilityId: z.string().nullish(),
    minHoursForActiveStreak: z.number(),
    enabled: z.boolean(),
    hcpExperienceEnabled: z.boolean().nullish(),
    hcfExperienceEnabled: z.boolean().nullish(),
    payBoostEnabled: z.boolean(),
    payBoostPercentage: z.number(),
  }),
});

export type StreaksSettings = z.infer<typeof streakSettingsSchema>;

const streakSettingsApiResponseSchema = z.object({
  data: streakSettingsSchema,
});

export type StreakSettingsApiResponse = z.infer<typeof streakSettingsApiResponseSchema>;

const streakSettingsRequestSchema = z.object({
  facilityId: z.string().optional(),
  msa: z.string().optional(),
});

export type StreakSettingsRequest = z.infer<typeof streakSettingsRequestSchema>;

export function useGetStreaksSettings(
  params: GetStreakSettingsParams,
  options?: UseGetQueryOptions<StreakSettingsApiResponse>
): UseQueryResult<StreakSettingsApiResponse> {
  const { facilityId, msa } = getStreakSettingsParamsSchema.parse(params);

  const queryParams: StreakSettingsRequest = {};
  if (isDefined(facilityId)) {
    queryParams.facilityId = facilityId;
  } else if (isDefined(msa)) {
    queryParams.msa = msa;
  }

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/streaks/settings`,
    queryParams,
    requestSchema: streakSettingsRequestSchema,
    responseSchema: streakSettingsApiResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_STREAKS_SETTINGS_FAILURE,
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
}
