import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { Box, LinearProgress, Stack } from "@mui/material";
import { formatRate } from "@src/appV2/Shifts/MyShifts/utils";
import { StreakBonusAtFacilityCard } from "@src/appV2/Streaks/components/StreakBonusAtFacilityCard";
import { type Streak } from "@src/appV2/Streaks/types";

export interface StreakBonusTrackerListProps {
  totalBonusPay: number;
  activeStreaks: Streak[];
  isLoading: boolean;
  onFacilityClick: (facilityId: string) => void;
}

export function StreakBonusTrackerList(props: StreakBonusTrackerListProps) {
  const { totalBonusPay = 0, activeStreaks, isLoading, onFacilityClick } = props;

  const bonusPayoutMessage =
    "Has been earned in Streak extra pay so far this week. Earn even more by completing shifts at the following facilities this week:";

  return (
    <>
      <Stack direction="row" alignItems="end" justifyContent="flex-start">
        <Text variant="h1">Your Active Streaks</Text>
        <LocalFireDepartmentOutlinedIcon
          sx={{
            fontSize: 40,
            color: "#ff5722",
          }}
        />
      </Stack>
      {!isLoading && isDefined(totalBonusPay) && totalBonusPay > 0 && (
        <Stack direction="row" alignItems="center" justifyContent="space-around">
          <Text variant="h1">{formatRate(totalBonusPay)}</Text>
        </Stack>
      )}

      {activeStreaks.length > 0 ? (
        <Stack spacing={1}>
          {!isLoading && (
            <Text variant="body1">
              {totalBonusPay > 0
                ? bonusPayoutMessage
                : "Receive an extra payout by completing shifts at these facilities this week:"}
            </Text>
          )}
          {isLoading ? (
            <LinearProgress sx={{ height: 4 }} variant="indeterminate" />
          ) : (
            activeStreaks.map((streak) => {
              return (
                <Box key={streak.attributes.facilityId}>
                  <StreakBonusAtFacilityCard
                    facilityId={streak.attributes.facilityId}
                    payBoostPercentage={streak.attributes.payBoostPercentage}
                    onClick={() => {
                      onFacilityClick(streak.attributes.facilityId);
                    }}
                  />
                </Box>
              );
            })
          )}
        </Stack>
      ) : (
        <Text variant="body1">You don&apos;t have active Streaks this week.</Text>
      )}
    </>
  );
}
