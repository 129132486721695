import { Text } from "@clipboard-health/ui-react";
import { Stack, type SxProps, type Theme } from "@mui/material";
import { type PayModifier } from "@src/appV2/Streaks/components/PayModifierBar";

interface PayModifierTagProps {
  payModifier: PayModifier;
  sx?: SxProps<Theme>;
}

export function PayModifierTag(props: PayModifierTagProps) {
  const { payModifier, sx } = props;

  return (
    <Stack direction="row" alignItems="center" sx={{ ...sx, color: payModifier.color }}>
      {payModifier.icon}
      <Text bold sx={{ color: payModifier.color }} variant="subtitle2">
        &nbsp;{payModifier.name}
      </Text>
    </Stack>
  );
}
