import { InternalLink } from "@clipboard-health/ui-react";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import { useTheme } from "@mui/material";

import { STREAK_TRACKER_PATH } from "../paths";
import { PayModifierBar } from "./PayModifierBar";

interface StreakPayModifierBarProps {
  streakPayDescription: string;
}

function StreakPayModifierDescription(props: StreakPayModifierBarProps) {
  const { streakPayDescription } = props;
  return (
    <>
      {streakPayDescription}
      {" For more information on your Streaks, visit the "}
      <InternalLink underline="hover" to={STREAK_TRACKER_PATH}>
        {"Status > Streaks page"}
      </InternalLink>
      .
    </>
  );
}

export function StreakPayModifierBar(props: StreakPayModifierBarProps) {
  const { streakPayDescription } = props;
  const theme = useTheme();

  return (
    <PayModifierBar
      payModifiers={[
        {
          name: "Streak",
          icon: <LocalFireDepartmentOutlinedIcon fontSize="small" />,
          color: theme.palette.error.light,
          description: <StreakPayModifierDescription streakPayDescription={streakPayDescription} />,
        },
      ]}
    />
  );
}
