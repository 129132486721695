import { Text } from "@clipboard-health/ui-react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { StreakProgressBarCard } from "@src/appV2/Streaks/components/StreakProgressBarCard";
import { type StreakProgress } from "@src/appV2/Streaks/types";

interface StreaksProgressListProps {
  streakProgressList: StreakProgress[];
  isLoading: boolean;
  clickOnFacility: (facilityId: string) => void;
}

export function StreakProgressList(props: StreaksProgressListProps) {
  const { streakProgressList, isLoading, clickOnFacility } = props;
  return (
    <Box>
      <Stack spacing={1}>
        <Text variant="h1">This Week&apos;s Progress</Text>
        {streakProgressList.length > 0 ? (
          <Stack spacing={1}>
            <Text variant="body1">
              Unlock Streak rewards next week by working enough hours this week at these facilities.
              Break times also count towards your progress.
            </Text>
            {isLoading ? (
              <LinearProgress sx={{ height: 4 }} variant="indeterminate" />
            ) : (
              streakProgressList.map((streakProgress) => {
                return (
                  <StreakProgressBarCard
                    key={streakProgress.attributes.facilityId}
                    facilityName={streakProgress.attributes.facilityName}
                    minHoursForActiveStreak={streakProgress.attributes.minHoursForActiveStreak}
                    status={streakProgress.attributes.status}
                    verifiedHoursInStreakPeriod={
                      streakProgress.attributes.verifiedHoursInStreakPeriod
                    }
                    unverifiedHoursInStreakPeriod={
                      streakProgress.attributes.unverifiedHoursInStreakPeriod
                    }
                    onClick={() => {
                      clickOnFacility(streakProgress.attributes.facilityId);
                    }}
                  />
                );
              })
            )}
          </Stack>
        ) : (
          <Text variant="body1">You don&apos;t have booked or verified hours yet this week.</Text>
        )}
      </Stack>
    </Box>
  );
}
